import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Özgürlüğe Yolculuk
			</title>
			<meta name={"description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:title"} content={"Özgürlüğe Yolculuk"} />
			<meta property={"og:description"} content={"Amacımız size yalnızca en kaliteli bisikletleri sunmak değil, aynı zamanda her yolculuğun unutulmaz olmasını sağlayacak destek ve kaynakları da sunmaktır."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2aefdcae00021e2f754/images/1-1%20%281%29.jpg?v=2024-06-07T06:19:36.894Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" />
			<Override slot="text1">
				Özgürlüğe Yolculuk
			</Override>
			<Override slot="text3" />
			<Override slot="text2">
				Sürüş heyecanının olağanüstü hizmet ve rakipsiz değerle buluştuğu SpeedThrill Motosiklet Kiralama'ya hoş geldiniz. Çıktığınız her yolculuğun heyecan, konfor ve güvenlikle dolu olmasını sağlayarak size en iyi motosiklet kiralama deneyimini sunmaya kendimizi adadık. İster deneyimli bir sürücü olun ister ilk kez maceraya atılan biri olun, geniş filomuz ve güler yüzlü personelimiz her ihtiyacınızı karşılamak için burada.
			</Override>
			<Override slot="text" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 2rem 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				SpeedThrill Motosiklet Kiralama olarak, motosiklet sürmenin getirdiği eşsiz keyif ve özgürlüğün bilincindeyiz.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					align-items="center"
					align-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41">
							SpeedThrill Motosiklet Kiralama olarak, motosiklet sürmenin getirdiği eşsiz keyif ve özgürlüğün bilincindeyiz. Misyonumuz bu heyecan verici deneyimi herkes için erişilebilir kılmaktır. Bisikletlerimizin her zaman en iyi durumda olmalarını sağlamak için titizlikle bakımları yapılır ve düzenli olarak bakımı yapılır. Şık spor bisikletlerden sağlam kruvazörlere kadar farklı tercihlere ve sürüş tarzlarına uyacak çeşitli modeller sunuyoruz.
Ekibimiz, bilgilerini ve sürüş sevgisini paylaşmaya istekli, tutkulu motosiklet tutkunlarından oluşuyor. Sadece bisiklet kiralamanın ötesine geçen kişiselleştirilmiş bir hizmet sunmaya inanıyoruz. Doğru modeli seçmenize yardımcı olmaktan ayrıntılı haritalar ve rota önerileri sağlamaya kadar yolculuğunuzun her yönünü geliştirmek için buradayız.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08:18:16.267Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					order="1"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 2px 0 0"
						border-style="solid"
						border-color="#333b41"
						padding="0px 25px 0px 0px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41" text-align="right">
							Çeşitli Filo: Kapsamlı motosiklet seçeneklerimiz, her sürücü için mükemmel bir sürüşe sahip olmamızı sağlar. İster hız ister, ister rahat bir yolculuk tercih edin, tarzınıza uygun bir bisikletimiz var.
Birinci Sınıf Bakım: Güvenlik bizim en büyük önceliğimizdir. Tüm motosikletlerimiz, en yüksek performansı ve güvenilirliği garanti etmek için sıkı denetimlerden ve düzenli bakımlardan geçmektedir.
Uzman Desteği: Bilgili personelimiz tavsiye, ipucu ve destek sunmak için her zaman hazırdır. Ekipman, rotalar veya sürüş teknikleri konusunda yardıma ihtiyacınız varsa, yanınızdayız.
Esnek Kiralama Planları: Saatlik ücretlerden uzun vadeli kiralamalara kadar programınıza ve bütçenize uygun çeşitli kiralama seçenekleri sunuyoruz. Kendi şartlarınıza göre sürüş özgürlüğünün tadını çıkarın.
Rekabetçi Fiyatlandırma: Uygun fiyatlarımız ve özel tekliflerimizle paranızın karşılığını en iyi şekilde alın. SpeedThrill'de kalite ve uygun fiyat bir aradadır.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08:18:16.258Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Sürmeye hazır?
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					SpeedThrill Motosiklet Kiralama ile açık yolun heyecanını yaşayın. İster yeni arazileri keşfediyor olun ister tanıdık rotalarda geziniyor olun, bisikletlerimiz ve hizmetlerimiz yolculuğunuzu geliştirmek için tasarlanmıştır. Sürüşünüzü ayırtmak ve sürüş özgürlüğünü açığa çıkarmak için hemen bizimle iletişime geçin!
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08:18:16.251Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});